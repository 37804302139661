import { gql } from '@apollo/client';

export const GROUP_MANAGERS = gql`
  query GetGroupManagers {
    permissionsList(
      filter: { title: { includesInsensitive: "can manage groups" } }
    ) {
      id
      rolePermissionBindingsList {
        id
        role {
          id
          title
          accountRoleBindingsList {
            id
            account {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
      title
    }
  }
`;

import * as API from '../../../API';

/**
 * @param {string} lectureId
 * @param {@type {{  text: string; timestamp: number | undefined; }}} pearl
 * @param {*} displayOrder number
 */
export const createPearl = async (
  lectureId,
  { text, timestamp },
  displayOrder
) => {
  await API.pearl.create({
    type: 'pearl',
    attributes: {
      displayOrder,
      text,
      timestamp
    },
    relationships: {
      lecture: {
        data: {
          type: 'lecture',
          id: lectureId
        }
      }
    }
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu, Spin } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

import * as API from '../../API';
import _ from 'lodash';

const { SubMenu } = Menu;
const menuItemStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};
let innerAntProps = {};

export default class CommentNavMenu extends React.Component {
  static propTypes = {
    courseId: PropTypes.string
  };

  static headers = {};

  constructor(props) {
    super(props);
    // antMenu passes props to children; must continue the pass to move submenu here
    const { ...antMenuProps } = props;
    innerAntProps = { ...antMenuProps };
    this.state = {
      links: [],
      pendingQueries: 0,
      loading: true,
      error: null
    };
  }

  async componentDidMount() {
    this.setState({
      links: await this.getCommentCourses(),
      loading: false,
      hasData: true
    });
  }

  getCommentCourses = async () => {
    const coursesWithComments = await API.fetchAllPages(
      API.courseModeratorBinding.all()
    ).then(async bindings => {
      // there can be multiple moderaters for each course
      const uniqueCourseIds = _.uniq(
        bindings.map(binding => binding.relationships.course.data.id)
      );

      const courses = await API.course.where({
        filter: { id: uniqueCourseIds }
      });

      return _.sortBy(courses.data, course => course.attributes.title);
    });
    return coursesWithComments;
  };

  render() {
    if (this.state.error !== null) {
      throw this.state.error;
    }
    const { links, loading } = this.state;

    return loading ? (
      <Menu.Item key="loading" {...innerAntProps}>
        <Spin /> Loading Moderated Courses
      </Menu.Item>
    ) : (
      <SubMenu
        key="comments"
        title={
          <span style={menuItemStyles}>
            <MessageOutlined />
            Comments
          </span>
        }
        {...innerAntProps}
      >
        {links?.map(item => {
          return (
            <Menu.Item key={item.id} {...innerAntProps}>
              <Link
                to={{
                  pathname: `/comments/${item.id}`,
                  state: `${item.attributes.title}`
                }}
              >
                {item.attributes.title}
              </Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  }
}

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ContentHeader from '../content/ContentHeader';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';

import { debounce, isNull } from 'lodash';
import { GET_GROUPS_QUERY } from './queries/GroupsQueries';
import {
  DELETE_GROUP_MUTATION,
  CREATE_GROUP_MUTATION
} from './queries/GroupQueries';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Empty,
  Input,
  Popconfirm,
  Table,
  Pagination,
  message
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import MessageLoader from '../../components/MessageLoader';

import './Groups.css';

const { Column } = Table;

export default function Groups() {
  const [searchGroupNameFilter, setSearchGroupNameFilter] = useState('');
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState(['NAME_ASC']);

  const history = useHistory();

  const { data, fetchMore, refetch, loading } = useQuery(GET_GROUPS_QUERY, {
    variables: {
      limit,
      offset,
      orderBy
    }
  });

  const [deleteGroup] = useMutation(DELETE_GROUP_MUTATION, {
    onCompleted: async (d, clientOptions) => {
      await refetch({
        limit,
        first: offset,
        filter: { name: { includesInsensitive: searchGroupNameFilter } },
        orderBy
      });
    },
    onError: error => {
      message.error('Error deleting group' + error);
    }
  });

  const [createGroup] = useMutation(CREATE_GROUP_MUTATION);

  useEffect(() => {
    if (!isNull(searchGroupNameFilter)) {
      refetch({
        limit,
        first: offset,
        filter: { name: { includesInsensitive: searchGroupNameFilter } }
      });
    }
  }, [refetch, searchGroupNameFilter, limit, offset, orderBy]);

  const handleCreateGroup = async () => {
    const newGroupId = uuidv4();
    await createGroup({
      variables: {
        id: newGroupId,
        name: 'New Group',
        shortname: `new-group-${newGroupId}`,
        createdAt: new Date(),
        updatedAt: new Date()
      }
    });
    history.push(`/group-management/group/${newGroupId}`);
  };

  const handleDeleteGroup = async idToDelete => {
    await deleteGroup({
      variables: {
        id: idToDelete
      }
    });
  };

  const debouncedSearchFilterChanged = debounce(async incomingSearchValue => {
    setSearchGroupNameFilter(incomingSearchValue);
  }, 500);

  const handleSearchFilterChange = useCallback(debouncedSearchFilterChanged, [
    debouncedSearchFilterChanged
  ]);

  const sorterToOrderByMapping = {
    createdAt: {
      ascend: 'CREATED_AT_ASC',
      descend: 'CREATED_AT_DESC'
    },
    updatedAt: {
      ascend: 'UPDATED_AT_ASC',
      descend: 'UPDATED_AT_DESC'
    },
    name: {
      ascend: 'NAME_ASC',
      descend: 'NAME_DESC'
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const programsOrderBy = [
      sorterToOrderByMapping[sorter.columnKey]?.[sorter.order]
    ] || ['NAME_ASC'];

    setOrderBy(programsOrderBy);

    fetchMore({
      variables: {
        limit,
        offset: (pagination.current - 1) * pagination.pageSize,
        orderBy: programsOrderBy
      }
    });
  };

  const commentsBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.GroupManagement,
    { title: 'Groups' }
  ];

  return (
    <div>
      <ContentHeader
        className="groups-header"
        breadCrumb={commentsBreadcrumb}
        hasTitle={true}
        title="Groups"
      />
      <div className="groups-menu-bar">
        <Input
          className="groups-header__search"
          allowClear={true}
          placeholder="Search Groups by name"
          onChange={e => handleSearchFilterChange(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent form submission
            }
          }}
        />
        <div className="groups-header__new">
          <Button
            className="groups-header__new"
            type="primary"
            onClick={_ => handleCreateGroup()}
          >
            <>
              <PlusOutlined style={{ display: 'contents' }} /> New Group
            </>
          </Button>
        </div>
      </div>
      {loading ? (
        <MessageLoader />
      ) : !loading && data ? (
        <>
          <Table
            dataSource={data.programs?.nodes.map(group => group)}
            className="groups_table"
            pagination={false}
            rowKey="id"
            onRow={record => ({
              onClick: event => {
                if (event.target?.classList?.contains('ant-table-cell')) {
                  history.push({
                    pathname: `/group-management/group/${record.id}`,
                    state: {
                      groupId: record.id,
                      groupName: record.name
                    }
                  });
                }
              }
            })}
            onChange={handleTableChange}
          >
            <Column dataIndex="name" key="name" title="Group Name" />
            <Column
              dataIndex={['manager', 'email']}
              key="managerEmail"
              title="Hippo Account Manager"
            />
            <Column
              title="Subscriptions"
              key="subscriptionCount"
              render={group => group.programCourseBindings.totalCount}
            />
            <Column
              title="Members"
              key="memberCount"
              render={group => group.programAccountBindings.totalCount}
            />
            <Column
              key="createdAt"
              title="Date Created"
              sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
              sortDirections={['descend', 'ascend']}
              render={group =>
                format(new Date(group.createdAt), 'MMM dd, yyyy')
              }
            />
            <Column
              key="updatedAt"
              title="Date Updated"
              sorter={(a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)}
              sortDirections={['descend', 'ascend']}
              render={group =>
                format(new Date(group.updatedAt), 'MMM dd, yyyy')
              }
            />
            <Column
              width="1vw"
              title=""
              dataIndex=""
              render={(
                _,
                { id, programAccountBindingsList, programCourseBindings }
              ) => {
                return (
                  <Popconfirm
                    placement="topRight"
                    title="Delete this group?"
                    onConfirm={e => handleDeleteGroup(id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      size="small"
                      disabled={
                        programAccountBindingsList.length > 0 ||
                        programCourseBindings.totalCount > 0
                      }
                    />
                  </Popconfirm>
                );
              }}
            />
          </Table>
          <Pagination
            current={offset / limit + 1}
            onChange={(pageNumber, pageSize) => {
              setLimit(pageSize);
              setOffset(pageSize * (pageNumber - 1));
              fetchMore({
                variables: { offset, first: limit }
              });
            }}
            total={data.programs.totalCount}
            pageSize={limit}
            showSizeChanger={true}
          />
        </>
      ) : (
        <Empty description="No groups found" />
      )}
    </div>
  );
}

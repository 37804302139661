import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone/builds/moment-timezone-with-data';

import {
  CalendarOutlined,
  PushpinOutlined,
  ProfileOutlined,
  DeleteOutlined
} from '@ant-design/icons';

import { Button, Empty, Table, Tooltip } from 'antd';
import Episode from './Episode';
import FillMainView from '../../components/FillMainView';

import * as API from '../../API';
import getSlug from '../../utils/getSlug';
import PaginatedList from '../../components/PaginatedList/PaginatedList';
import ListHeaderActionItems from '../../components/PaginatedList/ListHeaderActionItems';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';

import './EpisodeView.css';

const { Column } = Table;

class EpisodeView extends React.Component {
  static propTypes = {
    defaultCourseId: PropTypes.string,
    course: PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({}),
      relationships: PropTypes.shape({}),
      links: PropTypes.shape({})
    }),
    courseList: PropTypes.array
  };

  static defaultProps = {
    course: undefined,
    episode: undefined,
    chapter: undefined,
    courseList: []
  };

  constructor(props) {
    super(props);

    this.state = {
      episodesDescending: undefined,
      editingEpisode: null,
      pendingQueries: 0,
      error: null,
      courseList: props.courseList || [],
      defaultCourseId: props.defaultCourseId || null
    };
  }

  async componentDidMount() {
    this.setInitialEditingEpisode();
  }

  getCourseId = () => this.props.course && this.props.course.id;

  getResourceFilter = () =>
    Object.assign(
      { activatedAt: '@any' },
      this.getCourseId() ? { course: this.getCourseId() } : {}
    );

  getResourceOptions = () => ({
    sort: '-activatedAt',
    include: 'course,playlistType'
  });

  setInitialEditingEpisode = async () => {
    const { episodeId } = this.props.match.params;
    if (episodeId) {
      const episode = await this.getEpisode(episodeId);
      this.setState({ editingEpisode: episode });
    }
  };

  getEpisode = async episodeId => {
    try {
      const episode = (
        await API.episode.find({
          id: episodeId,
          options: {}
        })
      ).data;
      return episode;
    } catch (error) {
      this.setState({ error });
    }
  };

  checkForDuplicates = async (field, value) => {
    try {
      const response = await API.episode.where({
        filter: {
          [field]: value
        }
      });
      return response.data.length !== 0;
    } catch (error) {
      this.setState({ error });
    }
  };

  createNewUrlSlug = async title => {
    let urlSlug = getSlug(null, title, []);
    const isDuplicateUrlSlug = await this.checkForDuplicates(
      'urlSlug',
      urlSlug
    );

    if (isDuplicateUrlSlug) {
      let i = 2;
      while (isDuplicateUrlSlug) {
        const newUrlSlug = urlSlug + '-' + i;
        const isDuplicateUrlSlug = await this.checkForDuplicates(
          'urlSlug',
          newUrlSlug
        );
        if (!isDuplicateUrlSlug) {
          urlSlug = newUrlSlug;
          break;
        }
        i++;
      }
    }
    return urlSlug;
  };

  createNewTitle = async () => {
    let title = 'New Episode';
    const isDuplicateTitle = await this.checkForDuplicates('title', title);

    if (isDuplicateTitle) {
      let i = 2;
      while (isDuplicateTitle) {
        const newTitle = title + ' ' + i;
        const isDuplicateTitle = await this.checkForDuplicates(
          'title',
          newTitle
        );
        if (!isDuplicateTitle) {
          title = newTitle;
          break;
        }
        i++;
      }
    }
    return title;
  };

  createEpisode = async () => {
    try {
      const playlistType = (
        await API.playlistType.where({
          filter: {
            shortname: 'monthly-playlist'
          }
        })
      ).data[0];

      // If user is coming from Content => Audio Content => Episodes (via AudioEpisodeView.jsx) the courseList and defaultCourseId will be empty
      // If user is coming from Courses => Audio => Podcast (via AudioContentView.jsx) the courseList and defaultCourseId will be set
      if (!this.state.defaultCourseId && this.state.courseList.length === 0) {
        const podcastCourseType = (
          await API.courseType.where({
            filter: { shortname: 'podcast' }
          })
        ).data.pop();

        const coursesResponse = (
          await API.course.where({
            filter: {
              courseType: podcastCourseType.id
            },
            options: {
              sort: 'title'
            }
          })
        ).data;

        this.setState({ courseList: coursesResponse });
        if (coursesResponse[0] && coursesResponse[0].id) {
          this.setState({ defaultCourseId: coursesResponse[0].id });
        }
      }

      const title = await this.createNewTitle();
      const urlSlug = await this.createNewUrlSlug(title);

      const response = await API.episode.create({
        attributes: {
          title,
          urlSlug,
          isFullEpisode: true,
          activatedAt: moment()
            .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
            .add({ month: 1 })
            .startOf('month')
            .add({ hours: 7 })
        },
        relationships: {
          course: {
            data: {
              type: 'course',
              id: this.state.defaultCourseId
            }
          },
          playlistType: {
            data: {
              type: 'playlistType',
              id: playlistType.id
            }
          }
        }
      });
      this.setState({ editingEpisode: response.data });
    } catch (error) {
      this.setState({ error });
    }
  };

  deleteEpisode = async episode => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete episode '${episode.title}'? ${episode
            .episodeChapterBindings.length > 0 &&
            `Doing this will dissassociate ${episode.episodeChapterBindings.length} chapters from the Episode but will not delete any Chapter data`}`
        )
      ) {
        return;
      }
      if (episode.episodeChapterBindings.length > 0) {
        await Promise.all(
          episode.episodeChapterBindings.map(async binding => {
            await API.episodeChapterBinding.delete({ id: binding.id });
          })
        );
      }
      await API.episode.delete({
        id: episode.id
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  getSlug = (id, title) => getSlug(id, title, []);
  stopEditing = () => {
    this.props.history.push(`/content/episode/${this.state.editingEpisode.id}`);
    this.props.history.replace('/content/episodes/');
    this.setState({ editingEpisode: null });
  };

  filterEpisodes = (newValue, actions) => {
    switch (newValue) {
      case 'released':
        actions.setFilter(
          'activatedAt',
          '<' +
            moment()
              .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
              .toISOString()
        );
        break;
      case 'unreleased':
        actions.setFilter(
          'activatedAt',
          '>' +
            moment()
              .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
              .toISOString()
        );
        break;
      default:
        actions.setFilter('activatedAt', null);
    }
  };

  render() {
    if (this.state.error !== null) {
      throw this.state.error;
    }

    const sortOptions = {
      title: 'Title A-Z',
      '-title': 'Title Z-A',
      '-activatedAt': 'Release Date DESC',
      activatedAt: 'Release Date ASC'
    };
    const filterOptions = {
      all: 'All',
      released: 'Released',
      unreleased: 'Unreleased'
    };

    function editingEpisodeBreadcrumb(episodeId, onClick) {
      return [
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Content,
        {
          ...BreadcrumbConstants.Episode,
          onClick
        },
        {
          to: `/content/episode/${episodeId}`,
          title: 'Episode'
        }
      ];
    }

    return this.state.editingEpisode ? (
      <FillMainView
        onClose={this.stopEditing}
        closeButtonText="Back to Episodes"
        breadcrumbData={editingEpisodeBreadcrumb(
          this.state.editingEpisode.id,
          this.stopEditing
        )}
      >
        <Episode
          episode={this.state.editingEpisode}
          getSlug={this.getSlug}
          courseList={this.state.courseList}
        />
      </FillMainView>
    ) : (
      <PaginatedList
        resource={API.episode}
        defaultOptions={this.getResourceOptions()}
        defaultFilter={this.getResourceFilter()}
        onLoadRows={resource => API.simplifyResource(resource)}
        pageLimit={20}
        peekSortFilter={sortFilter => {
          this.setState({ episodesDescending: sortFilter[0] === '-' });
        }}
        renderHeader={actions => (
          <ListHeaderActionItems
            actions={actions}
            onCreateNew={() => this.createEpisode()}
            createButtonText="Episode"
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            getResourceOptions={this.getResourceOptions}
            filter={newValue => this.filterEpisodes(newValue, actions)}
          />
        )}
        renderList={(data, refresh) => (
          <Table
            className="episode-table"
            dataSource={data}
            rowKey="id"
            pagination={false}
            onRow={record => ({
              onClick: event => {
                this.setState({ editingEpisode: record._original });
                this.props.history.push('/content/episodes/');
                this.props.history.replace(`/content/episode/${record.id}`);
              }
            })}
          >
            <Column
              title="Type"
              key="type"
              width={75}
              align="center"
              render={value => {
                return (
                  <Tooltip title={value.playlistType.title}>
                    {value.playlistType.shortname === 'monthly-playlist' ? (
                      <CalendarOutlined />
                    ) : value.playlistType.shortname === 'single-segment' ? (
                      <PushpinOutlined />
                    ) : (
                      <ProfileOutlined />
                    )}
                  </Tooltip>
                );
              }}
            />
            <Column title="Podcast" dataIndex={['course', 'title']} />
            <Column title="Title" dataIndex="title" />
            <Column
              title="Release Date"
              dataIndex="activatedAt"
              render={value => (
                <span>
                  {moment(value)
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .format('MMM D YYYY @ H:mm zz')}
                </span>
              )}
            />
            <Column title="Episode #" dataIndex="episodeNumber" />
            <Column
              title="No. of Chapters"
              dataIndex={['episodeChapterBindings', 'length']}
            />
            <Column
              title="Artwork"
              dataIndex="artwork"
              render={artwork =>
                artwork ? (
                  <img src={artwork} alt="Episode Art" />
                ) : (
                  <span>No Artwork</span>
                )
              }
            />
            <Column title="Summary" dataIndex="description" ellipsis={true} />
            <Column
              title=""
              key="action"
              render={(value, episode) => (
                <span>
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={async e => {
                      e.stopPropagation();
                      await this.deleteEpisode(episode);
                      refresh();
                    }}
                  />
                </span>
              )}
            />
          </Table>
        )}
        renderEmptyList={() => <Empty description="No episodes to show" />}
      />
    );
  }
}

export default withRouter(EpisodeView);

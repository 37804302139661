import { Input } from 'antd';
import React, { useState } from 'react';

function TimestampToSecondsConverterWidget() {
  const [timestampText, setTimestampText] = useState('13:25');
  const seconds = timestampText
    .split(':')
    .reverse()
    .reduce((acc, val, idx) => acc + Math.pow(60, idx) * parseInt(val, 10), 0);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Input
        style={{ maxWidth: '5rem' }}
        size="small"
        type="text"
        onChange={e => setTimestampText(e.target.value)}
        value={timestampText}
      />
      <span style={{ marginLeft: '.5rem' }}>
        is {isNaN(seconds) ? 'not a valid timestamp' : `${seconds} seconds`}.
      </span>
    </div>
  );
}

export default TimestampToSecondsConverterWidget;

import React from 'react';
import { Form, Input } from 'antd';

export default function ItemTextFields() {
  return (
    <div className="item__field-sections">
      <Form.Item
        label="Cart Summary Card Subtext"
        name="subText"
        extra="This appears under the Cart Summary 'card' in the cart"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Suggested Item Title"
        name="subscriptionTitle"
        extra="How this Item appears under 'Clinicians also purchased' (if different from Item title above)"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Suggested Item Description"
        name="descriptionText"
        extra="How this Item's one line description appears under 'Clinicians also purchased'"
      >
        <Input />
      </Form.Item>
    </div>
  );
}

import React, { useState } from 'react';
import { Label } from 'reactstrap';
import { notify } from 'react-notify-toast';
import getSlug from '../../utils/getSlug';
import moment from 'moment';
import { Space, Select } from 'antd';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import MessageLoader from '../../components/MessageLoader';
import { GROUP_MANAGERS } from '../../utils/PermissionsQuery';
import { useQuery } from '@apollo/client';

import * as API from '../../API';

import './Group.css';

export default function GroupDetails({ group, updateGroup, accountManager }) {
  const [shortnameErrorMessage, setShortnameErrorMessage] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(null);

  const { data } = useQuery(GROUP_MANAGERS);

  const accountManagers =
    (data?.permissionsList?.length > 0 &&
      data.permissionsList[0].rolePermissionBindingsList.length > 0 &&
      data.permissionsList[0].rolePermissionBindingsList[0].role
        ?.accountRoleBindingsList) ||
    [];

  const handleShortnameError = shouldShowShortnameError => {
    if (shouldShowShortnameError) {
      setShortnameErrorMessage(
        'Error updating shortname - make sure shortname is unique to this group'
      );
    } else {
      setShortnameErrorMessage(null);
    }
  };

  const editGroup = async (field, value) => {
    try {
      setLoadingDetails(true);
      const groupToEdit = group;
      let shouldUpdate = true;

      if (field === 'programManager') {
        await API.program.update({
          id: groupToEdit.id,
          relationships: {
            manager: {
              data: {
                type: 'account',
                id: value
              }
            }
          }
        });
        updateGroup();
      } else if (field === 'shortname') {
        const slugifiedValue = getSlug('', value, []);
        let response;
        try {
          response = await API.program.update({
            id: groupToEdit.id,
            attributes: { shortname: slugifiedValue }
          });
          shouldUpdate = response.data.attributes.shortname === slugifiedValue;
          if (!shouldUpdate) {
            handleShortnameError(true);
          }
        } catch (e) {
          !shouldUpdate && handleShortnameError(true);
        }
      } else {
        await API.program.update({
          id: groupToEdit.id,
          attributes: { [field]: value }
        });
      }
      shouldUpdate && updateGroup();
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    } finally {
      setLoadingDetails(false);
    }
  };
  return (
    <div className="group__form-item group__details">
      <EditableLabel
        className="group__details__name"
        defaultValue={group?.name}
        onChange={value => editGroup('name', value)}
      />

      <div>
        <Label>Hippo Account Manager:</Label>
        <Select
          placeholder="None"
          className="group__details__manager"
          defaultValue={accountManager?.id || null}
          onSelect={value => editGroup('programManager', value)}
        >
          {accountManagers.map(manager => (
            <Select.Option key={manager.account.id} value={manager.account.id}>
              {manager.account.firstName}
            </Select.Option>
          ))}
        </Select>
      </div>

      {loadingDetails ? (
        <MessageLoader />
      ) : (
        <>
          <div className="group__details__item">
            <Space>
              <Label>Created:</Label>
              <Label>{`${moment(group?.createdAt).format('MM/DD/YY')}`}</Label>
            </Space>
          </div>
          <div className="group__details__shortname">
            <Space>
              <Label>Shortname:</Label>
              <EditableLabel
                defaultValue={group?.shortname}
                onChange={value => editGroup('shortname', value)}
              />
              {shortnameErrorMessage && (
                <div className="group__short-name-error">
                  {shortnameErrorMessage}
                </div>
              )}
            </Space>
          </div>
        </>
      )}
    </div>
  );
}

import React from 'react';
import TimestampToSecondsConverterWidget from './TimestampToSecondsConverterWidget';

function LecturePearlsNotes() {
  return (
    <div>
      <div>
        CSV template is{' '}
        <a
          href="https://docs.google.com/spreadsheets/d/1dAMtUF4S1DtVW8HCSge18zqBahg2KK7jIFZEefkFrAY/edit?gid=0#gid=0"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . Timestamp is in seconds.
      </div>
      <div style={{ marginTop: '.5rem' }}>
        You can convert timestamp to seconds manually using this widget:
        <div style={{ marginTop: '.5rem' }}>
          <TimestampToSecondsConverterWidget />
        </div>
      </div>
    </div>
  );
}
export default LecturePearlsNotes;

import Papa from 'papaparse';

export const ValidationStates = {
  VALID: 'VALID',
  MISSING_FILE: 'MISSING_FILE',
  MISSING_CONTENT: 'MISSING_CONTENT',
  INVALID_HEADER: 'INVALID_HEADER',
  INVALID_FILE_TYPE: 'INVALID_FILE_TYPE',
  PARSE_ERROR: 'PARSE_ERROR'
};

/**
 * @param {string} text csv text
 */
export const validatePearlCsv = async text => {
  if (!text) {
    return ValidationStates.MISSING_CONTENT;
  }
  const [header] = text.replace('\r', '').split('\n');
  if (!['timestamp', 'text'].every(col => header.includes(col))) {
    return ValidationStates.INVALID_HEADER;
  }

  return ValidationStates.VALID;
};

/**
 * @param {import('antd/lib/upload/interface').UploadFile} file
 * @returns {Promise<import('papaparse').ParseResult<{ text: string; timestamp: number | undefined; }>>}
 */
export const parsePearlsCsv = async file => {
  return new Promise((res, rej) => {
    Papa.parse(file.originFileObj, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      error: err => rej(err),
      complete: async pearlResults => res(pearlResults)
    });
  });
};

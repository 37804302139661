import React from 'react';
import moment from 'moment';
import { notify } from 'react-notify-toast';

import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Select,
  Input,
  Checkbox,
  InputNumber,
  DatePicker,
  Button,
  Divider,
  Tooltip
} from 'antd';

import * as API from '../../API';
import { getCertificates, getAccreditors } from '../../utils/cme';
import RichTextEditor from '../../components/RichTextEditor';
import CmeAccreditorModal from '../../components/CmeAccreditorModal';
import CmeCertificateModal from '../../components/CmeCertificateModal';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';

import './CmeBucket.css';

import { getTokenFromCookie } from '../../utils/cookie';

// Types of Buckets -- Note that for episodes, buckets are on the course attached to the episode
const COURSE = 'course';
const EPISODE = 'episode';

class CmeBucket extends React.Component {
  state = {
    cmeBucket: null,
    certificates: [],
    accreditors: [],
    course: null,
    bucketType: null,
    bucketId: null,
    attachedItemId: null,
    isNew: true,
    loading: true,
    addingAccreditor: false,
    addingCertificate: false,
    editingCertificate: false,
    selectedCertificateId: null,
    certificateToEdit: null,
    frontMatterTemplate: null,
    error: null
  };

  handleError() {
    const { error } = this.state;
    if (error) {
      notify.show(`Error: ${error}`, 'error');
    }
  }

  handleServerError(json) {
    if (json.errors && json.errors.length > 0) {
      this.setState({ error: json.errors, loading: false });
      notify.show(
        `Error: ${json.errors[0].title} - ${json.errors[0].detail[0].message}`,
        'error'
      );
      return true;
    }
    return false;
  }

  async componentDidMount() {
    const { course, episodeId, bucketId } = this.props.match.params;
    this.setState({ loading: true, bucketId });

    await this.loadAccreditorsAndCertificates();

    if (course) {
      await this.loadCourse(course);
      await this.setState({ bucketType: COURSE, attachedItemId: course });
      await this.loadBucket();
    } else if (episodeId) {
      await this.loadCourseOnEpisode(episodeId);
      await this.setState({ bucketType: EPISODE, attachedItemId: episodeId });
      await this.loadBucket();
    } else {
      this.props.history.back();
    }

    this.loadBreadcrumbs();
  }

  loadBucket = async () => {
    const { bucketId, course } = this.state;

    if (bucketId) {
      const cmeBucket = course.cmeBuckets.find(
        bucket => bucket.id === bucketId
      );
      await this.setState({
        cmeBucket,
        selectedCertificateId: cmeBucket.certificate.id,
        frontMatterTemplate: cmeBucket.frontMatterTemplate,
        isNew: false
      });
    } else {
      await this.setState({
        cmeBucket: this.generateDefaultCmeBucket(),
        isNew: true
      });
    }
  };

  loadCourse = async courseId => {
    try {
      const course = API.simplifyResource(
        await API.course.find({
          id: courseId,
          options: {
            include:
              'cmeBuckets,cmeBuckets.cmeAccreditor,cmeBuckets.certificate,courseType'
          }
        })
      );

      this.setState({
        course,
        loading: false
      });
    } catch (error) {
      this.setState({ error });
      this.handleError();
    }
  };

  loadCourseOnEpisode = async episodeId => {
    try {
      const episode = API.simplifyResource(
        await API.episode.find({
          id: episodeId,
          options: {}
        })
      );

      const courseId = episode.course.id;
      await this.loadCourse(courseId);
    } catch (error) {
      await this.setState({ error });
      this.handleError();
    }
  };

  loadBreadcrumbs = () => {
    const { bucketType, attachedItemId, isNew, course } = this.state;
    const { pathname } = this.props.location;
    if (bucketType === EPISODE) {
      const breadcrumbData = [
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Content,
        BreadcrumbConstants.Episode,
        {
          to: `/content/episode/${attachedItemId}`,
          title: 'Episode'
        },
        {
          to: `/content/episode/${attachedItemId}/cme`,
          title: 'Manage Episode CME'
        },
        {
          to: pathname,
          title: isNew ? 'New Certificate' : 'Edit Certificate'
        }
      ];
      this.setState({ breadcrumbData });
    } else if (bucketType === COURSE) {
      const breadcrumbData = [
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Courses,
        {
          to: `/course/${course.courseType.title}`,
          title: `${course.courseType.title}`
        },
        { to: `/course/${course.id}`, title: `${course.title}` },
        { to: `/course/${attachedItemId}/cme`, title: 'Manage CME' },
        {
          to: pathname,
          title: isNew ? 'New Certificate' : 'Edit Certificate'
        }
      ];
      this.setState({ breadcrumbData });
    }
  };

  loadAccreditorsAndCertificates = async () => {
    const accreditors = await getAccreditors();
    const certificates = await getCertificates();

    this.setState({ accreditors, certificates });
  };

  createNewBucket = async (attributes, relationships) => {
    try {
      const newCmeBucket = await API.cmeBucket.create({
        attributes,
        relationships
      });
      const cmeBucket = API.simplifyResource(
        await API.cmeBucket.find({
          id: newCmeBucket.data.id,
          options: {
            include: 'cmeAccreditor,certificate'
          }
        })
      );
      this.setState({ cmeBucket });
    } catch (error) {
      await this.setState({ error });
      this.handleError();
    }
  };

  updateBucket = async (attributes, relationships) => {
    const { cmeBucket } = this.state;
    try {
      await API.cmeBucket.update({
        id: cmeBucket.id,
        attributes,
        relationships
      });
    } catch (error) {
      await this.setState({ error });
      this.handleError();
    }
  };

  generateDefaultCmeBucket() {
    // Default to midnight today and midnight a year from now in the app timezone
    const start = moment()
      .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
      .startOf('day')
      .utc();
    const end = moment()
      .add(1, 'year')
      .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
      .startOf('day')
      .utc();

    return {
      title: 'New Activity',
      maxCredits: null,
      activatedAt: start.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      expiredAt: end.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    };
  }

  getInitialValue(field) {
    let value = '';
    if (
      typeof this.state.cmeBucket === 'object' &&
      this.state.cmeBucket !== null
    ) {
      if (field === 'certificate') {
        value = this.state.cmeBucket.certificate
          ? this.state.cmeBucket.certificate.id
          : null;
      } else if (field === 'accreditor') {
        value = this.state.cmeBucket.cmeAccreditor
          ? this.state.cmeBucket.cmeAccreditor.id
          : null;
      } else {
        value = this.state.cmeBucket[field];
      }
    }

    if (field === 'activatedAt' || field === 'expiredAt') {
      value = value
        ? moment(value).tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
        : null;
    }

    return value;
  }

  onClickLink(link) {
    this.props.history.push(link);
  }

  async handleCertificateChanged(value) {
    if (value === 'new') {
      await this.handleNewCertificate();
    } else {
      this.setState({
        selectedCertificateId: value,
        certificateToEdit: null
      });
    }
  }

  async handleNewCertificate() {
    this.setState({
      addingCertificate: true
    });
  }

  async handleEditCertificate() {
    // Edit existing certificate
    const response = await fetch(
      process.env.REACT_APP_JSONAPI_SERVER +
        '/certificate/' +
        this.state.selectedCertificateId,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: 'Bearer ' + getTokenFromCookie()
        }
      }
    );
    const json = await response.json();
    if (this.handleServerError(json)) {
      return;
    }

    this.setState({
      editingCertificate: true,
      certificateToEdit: json.data
    });
  }

  async handleCertificateModalOk(certificate, e) {
    const certificates = this.state.certificates;

    if (this.state.addingCertificate) {
      certificates.push(certificate);
    } else {
      certificates.forEach(function(c, i) {
        if (c.id === certificate.id) {
          certificates[i] = certificate;
        }
      });
    }

    await this.setState({
      certificates,
      editingCertificate: false,
      addingCertificate: false,
      selectedCertificateId: certificate.id,
      certificateToEdit: null
    });

    this.props.form.setFieldsValue({ certificate: certificate.id });
  }

  async handleCertificateModalCancel(e) {
    this.setState({
      editingCertificate: false,
      addingCertificate: false,
      certificateToEdit: null
    });
  }

  async handleNewAccreditor(value) {
    if (value === 'new') {
      this.setState({
        addingAccreditor: true
      });
    }
  }

  async handleAccreditorModalOk(accreditor, e) {
    const accreditors = this.state.accreditors;
    accreditors.push(accreditor);
    await this.setState({ accreditors, addingAccreditor: false });
    this.props.form.setFieldsValue({ accreditor: accreditor.id });
  }

  async handleAccreditorModalCancel(e) {
    this.setState({
      addingAccreditor: false
    });
  }

  async handleSubmit(e) {
    const { course, isNew, frontMatterTemplate } = this.state;

    try {
      e.preventDefault();
      this.setState({ loading: true });

      const maxCredits = this.props.form.getFieldValue('maxCredits');

      const attributes = {
        title: this.props.form.getFieldValue('title'),
        maxCredits: maxCredits ? maxCredits : null,
        isDefaultCmeBucket: this.props.form.getFieldValue('isDefaultCmeBucket'),
        frontMatterTemplate: frontMatterTemplate ? frontMatterTemplate : null,
        activatedAt: this.props.form
          .getFieldValue('activatedAt')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        expiredAt: this.props.form
          .getFieldValue('expiredAt')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      };

      const relationships = {
        course: {
          data: {
            type: 'course',
            id: course.id
          }
        },
        certificate: {
          data: {
            type: 'certificate',
            id: this.props.form.getFieldValue('certificate')
          }
        },
        cmeAccreditor: {
          data: {
            type: 'cmeAccreditor',
            id: this.props.form.getFieldValue('accreditor')
          }
        }
      };

      if (isNew) {
        await this.createNewBucket(attributes, relationships);
        // Change new url to appropriate edit url
        this.props.history.replace(
          `${this.props.location.pathname}/${this.state.cmeBucket.id}`
        );
      } else {
        this.updateBucket(attributes, relationships);
      }
      this.setState({ isNew: false, loading: false });
      notify.show('CME credit was saved successfully.', 'success');
    } catch (error) {
      this.setState({ error, loading: false });
      notify.show(`Error: ${error.message}`, 'error');
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { breadcrumbData, loading, bucketType, attachedItemId } = this.state;

    return loading ? (
      <p>Loading...</p>
    ) : (
      <div className="cme-bucket">
        <BreadcrumbNavigation data={breadcrumbData} />

        <h5 className="cme-bucket__title">CME Credit</h5>

        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Item label="Activity Name">
            {getFieldDecorator('title', {
              initialValue: this.getInitialValue('title'),
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter a title.'
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item
            label={
              <Tooltip
                title={
                  <div>
                    For courses with multiple accreditors.
                    <br />
                    This acts as a fallback in the case that a user somehow
                    earns CME without explicitly selecting an accreditor during
                    onboarding
                  </div>
                }
              >
                Is Default CME Bucket <InfoCircleOutlined />
              </Tooltip>
            }
          >
            {getFieldDecorator('isDefaultCmeBucket', {
              initialValue: this.getInitialValue('isDefaultCmeBucket'),
              valuePropName: 'checked'
            })(<Checkbox />)}
          </Form.Item>
          <Form.Item label="Accreditor">
            {getFieldDecorator('accreditor', {
              initialValue: this.getInitialValue('accreditor'),
              rules: [
                {
                  required: true,
                  message: 'Please choose an accreditor.'
                }
              ]
            })(
              <Select onChange={this.handleNewAccreditor.bind(this)}>
                {this.state.accreditors
                  ? this.state.accreditors.map(function(accreditor) {
                      return (
                        <Select.Option key={accreditor.id}>
                          {accreditor.attributes.name}
                        </Select.Option>
                      );
                    })
                  : null}
                {
                  <Select.Option key="new">
                    <Divider style={{ margin: '4px 0' }} />
                    <PlusOutlined
                      style={{
                        padding: '0 8px',
                        cursor: 'pointer',
                        position: 'relative',
                        top: '-3px'
                      }}
                    />
                    New Accreditor
                  </Select.Option>
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Certificate">
            {getFieldDecorator('certificate', {
              initialValue: this.getInitialValue('certificate'),
              rules: [
                {
                  required: true,
                  message: 'Please choose a certificate.'
                }
              ]
            })(
              <Select onChange={this.handleCertificateChanged.bind(this)}>
                {this.state.certificates
                  ? this.state.certificates.map(function(certificate) {
                      return (
                        <Select.Option key={certificate.id}>
                          {certificate.attributes.name}
                        </Select.Option>
                      );
                    })
                  : null}
                {
                  <Select.Option key="new">
                    <Divider style={{ margin: '4px 0' }} />
                    <PlusOutlined
                      style={{
                        padding: '0 8px',
                        cursor: 'pointer',
                        position: 'relative',
                        top: '-3px'
                      }}
                    />
                    New Certificate
                  </Select.Option>
                }
              </Select>
            )}
            <Button
              disabled={!this.state.selectedCertificateId}
              onClick={this.handleEditCertificate.bind(this)}
            >
              View/Edit
            </Button>
          </Form.Item>
          <Form.Item label="Activation Date">
            {getFieldDecorator('activatedAt', {
              initialValue: this.getInitialValue('activatedAt'),
              rules: [
                {
                  type: 'object',
                  required: true,
                  whitespace: true,
                  message: 'Please enter the date that the disclosure begins.'
                }
              ]
            })(
              <DatePicker
                showTime={{ use12Hours: true, format: 'HH:mm a' }}
                format="YYYY-MM-DD HH:mm a"
              />
            )}
          </Form.Item>
          <Form.Item label="Expiry Date">
            {getFieldDecorator('expiredAt', {
              initialValue: this.getInitialValue('expiredAt'),
              rules: [
                {
                  type: 'object',
                  required: true,
                  whitespace: true,
                  message: 'Please enter the date that the disclosure expires.'
                }
              ]
            })(
              <DatePicker
                showTime={{ use12Hours: true, format: 'HH:mm a' }}
                format="YYYY-MM-DD HH:mm a"
              />
            )}
          </Form.Item>
          <Form.Item label="Max Credits">
            {getFieldDecorator('maxCredits', {
              initialValue: this.getInitialValue('maxCredits'),
              rules: [
                {
                  type: 'number',
                  message: 'Please enter a number, or leave blank for no cap.'
                }
              ]
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item
            colon={false}
            label={
              <Tooltip
                title={
                  <div>
                    For RAP courses only. {'{ body }'} in this field is replaced
                    with the Episode's Objective and Disclosures.
                    <br />
                    For all other course types, this field is unused. The Course
                    CME Disclosure is used as-is.
                  </div>
                }
              >
                Front Matter Template :&nbsp;
                <br />
                <InfoCircleOutlined />
              </Tooltip>
            }
          >
            {this.state.cmeBucket &&
              getFieldDecorator('frontMatterTemplate')(
                <RichTextEditor
                  className="episode__description"
                  defaultValue={this.state.cmeBucket.frontMatterTemplate}
                  onChange={value => {
                    this.setState({ frontMatterTemplate: value });
                  }}
                />
              )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            {this.state.course ? (
              <Button
                type="secondary"
                onClick={this.onClickLink.bind(
                  this,
                  bucketType === COURSE
                    ? `/course/${attachedItemId}/cme`
                    : `/content/episode/${attachedItemId}/cme`
                )}
              >
                Cancel
              </Button>
            ) : null}
          </Form.Item>
        </Form>
        <CmeAccreditorModal
          open={this.state.addingAccreditor}
          onOk={this.handleAccreditorModalOk.bind(this)}
          onCancel={this.handleAccreditorModalCancel.bind(this)}
        />
        {this.state.editingCertificate || this.state.addingCertificate ? (
          <CmeCertificateModal
            open={true}
            certificate={
              this.state.editingCertificate
                ? this.state.certificateToEdit
                : null
            }
            onOk={this.handleCertificateModalOk.bind(this)}
            onCancel={this.handleCertificateModalCancel.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default Form.create()(CmeBucket);

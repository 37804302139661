import { gql } from '@apollo/client';

export const GET_GROUPS_QUERY = gql`
  query GetGroups(
    $limit: Int
    $offset: Int
    $filter: ProgramFilter
    $orderBy: [ProgramsOrderBy!]
  ) {
    programs(
      first: $limit
      offset: $offset
      filter: $filter
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        name
        createdAt
        updatedAt
        manager {
          email
        }
        programAccountBindings(filter: { deletedAt: { isNull: true } }) {
          totalCount
          nodes {
            id
          }
        }
        programCourseBindings {
          totalCount
          nodes {
            id
          }
        }
        programAccountBindingsList {
          id
        }
      }
    }
  }
`;

import { UploadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Modal } from 'antd';
import React, { useState } from 'react';

import { usePearlsCsv } from './hooks';
import LecturePearlsDragger from './components/LecturePearlsDragger';
import LecturePearlsFileInfo from './components/LecturePearlsFileInfo';
import LecturePearlsNotes from './components/LecturePearlsNotes';

/**
 * @param {{ lectureId: string }} props
 */
function LecturePearlsCSVUploader({ lectureId }) {
  const [visible, setVisible] = useState(false);
  const {
    file,
    pearls,
    validationState,
    isValid,
    isCreating,
    setFile,
    create: createPearls
  } = usePearlsCsv(lectureId);

  return (
    <>
      <Button
        type="primary"
        size="small"
        icon={<UploadOutlined />}
        onClick={() => setVisible(true)}
      >
        Upload Pearls CSV
      </Button>
      <Modal
        title="Upload Lecture Pearls CSV"
        visible={visible}
        maskClosable={false}
        okButtonProps={{
          disabled: !isValid,
          loading: isCreating
        }}
        onCancel={() => {
          setVisible(false);
          setFile();
        }}
        onOk={async () => {
          try {
            await createPearls();
            setVisible(false);
            window.location.reload();
          } catch (err) {
            message.error(err);
          }
        }}
      >
        <LecturePearlsNotes />
        <Divider />
        <LecturePearlsDragger onChange={setFile} />
        {file && (
          <LecturePearlsFileInfo
            file={file}
            numberOfPearls={pearls?.length}
            validationState={validationState}
            isValid={isValid}
          />
        )}
      </Modal>
    </>
  );
}

export default LecturePearlsCSVUploader;

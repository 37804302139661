import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
  Checkbox,
  Input,
  InputNumber,
  Modal,
  DatePicker,
  Tooltip,
  Select
} from 'antd';
import { LinkOutlined, LockOutlined } from '@ant-design/icons';
import { useItemsCategories } from './hooks/useItemsCategories';

const priceUpdateContent = (
  <div>
    You're about to enable updating the price of a live item. Price changes will
    take place <i>immediately</i>
    <ul>
      <li> Please double-check your changes.</li>
      <li>Inform relevant teams of this update.</li>
      <li>
        Ensure sales page pricing module changes are synced with this change
      </li>
    </ul>
    <strong> Proceed with care.</strong>
  </div>
);

const skuChangeContent = (
  <div>
    You're about to enable updating the sku of this new item.{' '}
    <i>It can only be updated once.</i>
    <strong> Proceed with care.</strong>
  </div>
);

const skuUneditableMessage = (
  <span>This is the SKU for the item & is not editable to prevent mishaps</span>
);

export default function ItemFields({ activeItem, isNewItem, isNewItemSku }) {
  const itemsCategories = useItemsCategories();
  const [priceEditable, setPriceEditable] = useState(false);
  const [skuEditable, setSkuEditable] = useState(false);

  const skuModalOnClick = () => {
    Modal.confirm({
      title: 'SKU Change Notice',
      content: skuChangeContent,
      onOk() {
        setSkuEditable(true);
      }
    });
  };

  const priceModalOnClick = () => {
    Modal.confirm({
      title: 'Price Change Notice',
      content: priceUpdateContent,
      onOk() {
        setPriceEditable(true);
      }
    });
  };
  return (
    <Row gutter={{ sm: 16, lg: 40 }}>
      <Col sm={24} lg={12}>
        <Form.Item
          style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
          label={
            <div className="lock-label">
              SKU
              {isNewItemSku && !skuEditable && (
                <Tooltip title="Click to edit SKU ">
                  <LockOutlined
                    style={{ display: 'inline-block' }}
                    onClick={skuModalOnClick}
                  />
                </Tooltip>
              )}
            </div>
          }
          name="sku"
          tooltip={!isNewItemSku && skuUneditableMessage}
        >
          <Input disabled={!isNewItemSku || !skuEditable} />
        </Form.Item>
        <Form.Item
          label="Title"
          name="title"
          style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
          extra="Max length: 35 characters"
        >
          <Input maxLength="35" />
        </Form.Item>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col span={5.5}>
            <Form.Item
              style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
              label={
                <div className="lock-label">
                  Price
                  {!priceEditable && (
                    <Tooltip title="Click to edit price">
                      <LockOutlined
                        style={{ display: 'inline-block' }}
                        onClick={priceModalOnClick}
                      />
                    </Tooltip>
                  )}
                </div>
              }
              name="price"
            >
              <InputNumber
                disabled={!priceEditable}
                formatter={val => `$${val}`}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="Billing Period" name="billingPeriod">
              <Select>
                <Select.Option value={1}>Monthly</Select.Option>
                <Select.Option value={12}>1 Year</Select.Option>
                <Select.Option value={24}>2 Years</Select.Option>
                <Select.Option value={36}>3 Years</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Category"
              name="category"
              style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
            >
              <Select>
                {itemsCategories?.map(({ label, value }, index) => (
                  <Select.Option value={value} key={index}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: '1rem' }} align="middle" gutter={8}>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Braintree Plan ID
                  {activeItem.braintreePlanId && (
                    <a
                      style={{ marginLeft: '.5rem' }}
                      href={`${process.env.REACT_APP_BRAINTREE_URL}/plans/${activeItem.braintreePlanId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkOutlined
                        style={{ color: 'blue', fontSize: '1rem' }}
                      />
                    </a>
                  )}
                </span>
              }
              name="braintreePlanId"
            >
              <Input
                value={activeItem.braintreePlanId}
                disabled={
                  activeItem.hasOrderItems ||
                  (!activeItem.hasFreeTrial && !activeItem.allowArb)
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={24} lg={12}>
        <Row>
          <Form.Item
            label="Started At"
            name="startedAt"
            style={{
              backgroundColor: isNewItem ? '#EFFD5F' : 'none',
              marginRight: '2rem'
            }}
            extra="becomes active"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item label="Ended At" name="endedAt" extra="no longer active">
            <DatePicker />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            name="hasFreeTrial"
            valuePropName="checked"
            style={{ marginRight: '0.75rem' }}
          >
            <Checkbox checked={activeItem.hasFreeTrial}>
              Has Free Trial?
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="allowArb"
            valuePropName="checked"
            style={{ marginRight: '0.75rem' }}
          >
            <Checkbox checked={activeItem.allowArb}>Allow ARB?</Checkbox>
          </Form.Item>
        </Row>
        <Form.Item
          label="Free Trial Length (days):"
          name="freeTrialDuration"
          hidden={!activeItem.hasFreeTrial}
          style={{ paddingTop: '5px' }}
        >
          <InputNumber
            addonAfter={true}
            min={1}
            disabled={!activeItem.hasFreeTrial}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

import React from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import _ from 'lodash';
import { InboxOutlined } from '@ant-design/icons';

/**
 * @param {@type {{
 *  onChange: (file: import('antd/lib/upload/interface').UploadFile) => void
 * }}}
 */
function LecturePearlsDragger({ onChange }) {
  return (
    <Dragger
      accept=".csv"
      method={null}
      multiple={false}
      showUploadList={false}
      customRequest={_.noop}
      onChange={async ({ file }) => onChange(file)}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">Uploads a CSV file with all users.</p>
    </Dragger>
  );
}

export default LecturePearlsDragger;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import capitalize from 'lodash/capitalize';
import MessageLoader from '../components/MessageLoader';
import BreadcrumbConstants from '../routes/BreadcrumbConstants';
import ContentHeader from './content/ContentHeader';
import { useQuery } from '@apollo/client';
import {
  COURSES_LIST_QUERY,
  COURSETYPES_LIST_QUERY
} from './course/queries/CourseQueries';

export default function Courses({ courseType }) {
  const [courses, setCourses] = useState([]);
  const [courseTypeId, setCourseTypeId] = useState(null);

  const { data: courseTypesData, loading: courseTypesLoading } = useQuery(
    COURSETYPES_LIST_QUERY
  );

  const courseTypeVariable = courseType && { variables: { courseTypeId } };

  const { data, loading, refetch } = useQuery(COURSES_LIST_QUERY, {
    ...courseTypeVariable,
    skip: courseType && !courseTypeId
  });

  useEffect(() => {
    refetch();
  }, [courseTypeId, refetch]);

  useEffect(() => {
    if (data && data.coursesList) {
      setCourses(
        data.coursesList.map(course =>
          Object.assign({ key: course.id }, course)
        )
      ); // key props keep React and the antd.Table happy
    }
    if (courseTypesData && courseTypesData.courseTypesList) {
      const propCourseType = courseTypesData.courseTypesList.find(
        courseTypeResult => courseTypeResult.shortname === courseType
      );
      setCourseTypeId(propCourseType?.id);
    }
  }, [data, courseTypesData, courseType]);

  const tableColumns = [
    {
      title: 'Title',
      dataIndex: ['title'],
      key: 'title'
    },
    {
      title: 'Select',
      key: 'select',
      render: (text, record) => <Link to={'/course/' + record.id}>Select</Link>
    }
  ];

  const coursesBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Courses,
    {
      title: capitalize(courseType)
    }
  ];

  return (
    <div>
      <ContentHeader breadCrumb={coursesBreadcrumb} />
      {!loading && !courseTypesLoading && (
        <Table
          style={{ backgroundColor: '#fff' }}
          dataSource={courses}
          columns={tableColumns}
          pagination={false}
          onRow={record => ({
            onClick: () => {
              window.location = '/course/' + record.id;
            }
          })}
        />
      )}
      {(loading || courseTypesLoading) && <MessageLoader withRandom={true} />}
      <br />
    </div>
  );
}

import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ValidationStates } from '../csv';

/**
 * @param {@type {{
 *  file: import('antd/lib/upload/interface').UploadFile,
 *  numberOfPearls: number | undefined,
 *  validationState: import('../csv').ValidationStates | undefined
 *  isValid: boolean,
 * }}}
 */
function LecturePearlsFileInfo({
  file,
  numberOfPearls,
  validationState,
  isValid
}) {
  return (
    <>
      <div
        style={{
          marginTop: '1rem',
          color: isValid ? 'green' : 'red',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <span style={{ marginRight: '.5rem' }}>
          {isValid ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
        </span>
        {file?.name}&nbsp;
      </div>
      {isValid ? (
        <div>
          <span role="img" aria-label="pearl emoji">
            ⚪️ {numberOfPearls} pearls will be created
          </span>
        </div>
      ) : (
        <div style={{ color: 'red' }}>{getErrorMessage(validationState)}</div>
      )}
    </>
  );
}

export const getErrorMessage = validationState => {
  if (validationState === ValidationStates.MISSING_FILE) {
    return 'Missing file.';
  }
  if (validationState === ValidationStates.MISSING_CONTENT) {
    return 'Missing content.';
  }
  if (validationState === ValidationStates.INVALID_HEADER) {
    return 'Invalid header. Please make sure all columns are present.';
  }
  if (validationState === ValidationStates.INVALID_FILE_TYPE) {
    return 'Invalid file type.';
  }
  return '';
};

export default LecturePearlsFileInfo;

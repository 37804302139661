import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';

import FillMainView from '../../components/FillMainView';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import GroupMembers from './GroupMembers';
import GroupDetails from './GroupDetails';
import GroupSubscriptions from './GroupSubscriptions';
import Notes from '../../components/Notes/Notes';
import {
  GET_GROUP,
  GET_ACCOUNT_GROUP_SUBSCRIPTIONS
} from './queries/GroupQueries';

import './Group.css';

export default function Group() {
  const history = useHistory();
  const { id } = useParams();

  const { data: groupData, loading: loadingGroup, refetch } = useQuery(
    GET_GROUP,
    {
      variables: { id }
    }
  );

  const group = groupData?.program;
  const accounts = group?.programAccountBindingsList || [];
  const courseBindings = group?.programCourseBindingsList || [];
  const accountManager = group?.manager || null;

  const programCourseBindingIds = courseBindings?.map(sub => sub.id);

  const {
    data: accountGroupSubscriptions,
    loading: loadingSubscriptions,
    refetch: refetchSubscriptions
  } = useQuery(GET_ACCOUNT_GROUP_SUBSCRIPTIONS, {
    variables: { programCourseBindingIds },
    skip: programCourseBindingIds?.length < 1
  });

  const subscriptions =
    accountGroupSubscriptions?.subscriptionsList?.length > 0
      ? courseBindings?.reduce((bindingSubscriptions, courseBinding) => {
          const subscriptionsList = accountGroupSubscriptions?.subscriptionsList?.filter(
            subscription =>
              subscription.programCourseBindingId === courseBinding.id
          );
          bindingSubscriptions.push({
            ...courseBinding,
            ...{ subscriptionsList }
          });
          return bindingSubscriptions;
        }, [])
      : [];

  return (
    <FillMainView
      onClose={() =>
        history.push({
          pathname: '/group-management/groups',
          state: { ...history.location.state }
        })
      }
      closeButtonText="Back to Groups"
      breadcrumbData={[
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.GroupManagement,
        { to: '/group-management/groups', title: 'Groups' },
        { title: group?.name || '' }
      ]}
    >
      <hr />
      {!loadingGroup ? (
        <div className="group">
          <GroupDetails
            group={group}
            updateGroup={refetch}
            accountManager={accountManager}
          />
          <hr />
          <div className="group__form-item">
            <GroupMembers
              memberType="Admin"
              group={group}
              accounts={accounts}
              subscriptions={subscriptions}
              history={history}
              loadingAccounts={loadingGroup}
              loadAccounts={refetch}
              loadSubscriptions={refetchSubscriptions}
            />
          </div>
          <hr />
          <GroupSubscriptions
            group={group}
            subscriptions={subscriptions}
            loadSubscriptions={refetch}
            loadingSubscriptions={loadingSubscriptions}
          />
          <hr />
          <GroupMembers
            memberType="Member"
            group={group}
            accounts={accounts}
            subscriptions={subscriptions}
            history={history}
            loadingAccounts={loadingGroup}
            loadAccounts={refetch}
            loadSubscriptions={refetchSubscriptions}
          />
        </div>
      ) : (
        <Spin />
      )}
      {group && (
        <Notes
          bindingType="program"
          bindingTypeId={group?.id}
          title="Program Notes"
        />
      )}
    </FillMainView>
  );
}

Group.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

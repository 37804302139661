import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Collapse, message, Space, Table, Tag, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { formatTimeHoursMinutesSeconds } from '../../../../utils/time';
import {
  groupEarnedCmesByBucket,
  groupEarnedCmesByContent,
  getCmeAttemptContentType,
  getEpisodeForCmeAttempt,
  getAssessmentForCmeAttempt,
  getQbankForCmeAttempt,
  groupRawContent
} from './../util';
import { useQuery } from '@apollo/client';
import { COURSE_DETAIL_QUERY } from './CourseDetailQuery';
import { COURSE_CONTENT_QUERY } from './CourseContentQuery';

const { Panel } = Collapse;

export function CourseCmePanel({
  accountId,
  courseOrProductId,
  buckets,
  groupBy,
  courseType
}) {
  const [groupedData, setGroupedData] = useState(null);

  const { data } = useQuery(COURSE_DETAIL_QUERY, {
    variables: {
      accountId,
      bucketIds: buckets.map(bucket => bucket.id)
    }
  });

  const { data: rawContent } = useQuery(COURSE_CONTENT_QUERY, {
    variables: {
      courseId: courseOrProductId
    },
    skip: groupBy !== 'content'
  });

  useEffect(() => {
    if (data?.accountEarnedCmesList) {
      if (groupBy === 'bucket') {
        const bucketed = groupEarnedCmesByBucket(data?.accountEarnedCmesList);
        setGroupedData(bucketed);
      } else if (groupBy === 'content') {
        setGroupedData(
          groupEarnedCmesByContent(
            groupRawContent(rawContent?.course),
            data?.accountEarnedCmesList,
            courseOrProductId
          )
        );
      }
    }
  }, [data, groupBy, courseOrProductId, rawContent]);

  const copyIncompleteContentToClipboard = useCallback(() => {
    const incompleteData = groupedData.flatMap(group => {
      const attemptGroups = _.groupBy(
        group.cmeAttempts,
        attempt =>
          attempt.chapter?.id || attempt.lecture?.id || attempt.attachment?.id
      );

      const getItem = attempt =>
        attempt.lecture || attempt.chapter || attempt.attachment;

      const getItemTitle = attempt =>
        (attempt.lecture && attempt.lecture.title) ||
        (attempt.chapter && attempt.chapter.title) ||
        (attempt.attachment && attempt.attachment.title + ': attachment') ||
        '';

      return Object.values(attemptGroups)
        .map(attemptGroup => {
          const firstAttempt = _.first(attemptGroup);
          if (firstAttempt.type === 'attested') {
            // attested attempts are always complete
            return null;
          }

          const available = formatTimeHoursMinutesSeconds(
            getItem(firstAttempt)?.media?.duration ||
              getItem(firstAttempt)?.activityTimeInSeconds
          );

          const earned = formatTimeHoursMinutesSeconds(
            attemptGroup.reduce((total, attempt) => {
              return total + attempt.activityTimeInSeconds;
            }, 0)
          );

          const progress = {
            content: firstAttempt.content.title,
            title: getItemTitle(firstAttempt),
            earned,
            available
          };

          return progress.earned !== parseInt(progress.available, 10)
            ? progress
            : null;
        })
        .filter(Boolean);
    });

    const incompleteContentSummary = incompleteData
      .map(
        summary =>
          `${summary.content}${
            summary.title ? ': ' + summary.title : ''
          } | Earned ${summary.earned}/${summary.available}`
      )
      .join('\n');

    navigator.clipboard.writeText(incompleteContentSummary);
    message.info('Copied incomplete content summary to clipboard');
  }, [groupedData]);

  const groupDateInfo = group => {
    if (groupBy === 'content') {
      if (group.activatedAt) {
        return ` (${new Date(group.activatedAt).toLocaleDateString('en-US', {
          month: 'short',
          year: 'numeric'
        })})`;
      } else {
        return '';
      }
    } else {
      return (
        <>
          <br />
          <small>
            Valid:{' '}
            {new Date(group.activatedAt).toLocaleDateString('en-US', {
              month: 'short',
              year: 'numeric'
            })}
            {' - '}
            {new Date(group.expiredAt).toLocaleDateString('en-US', {
              month: 'short',
              year: 'numeric'
            })}
          </small>
        </>
      );
    }
  };

  return groupedData && groupedData?.length > 0 ? (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space direction="horizontal" align="end" style={{ width: '100%' }}>
        {groupBy === 'content' && (
          <Button onClick={copyIncompleteContentToClipboard}>
            Copy Incomplete Content Summary
          </Button>
        )}
      </Space>
      <Collapse defaultActiveKey={[groupedData[0].id]}>
        {groupedData.map(group => (
          <Panel
            header={
              <>
                {group.title}
                {groupDateInfo(group)}
              </>
            }
            key={group.id}
            extra={
              <Space>
                {_.has(group, 'totalCredits') ? (
                  <span>
                    <strong>Credits</strong> {group.totalCredits}
                  </span>
                ) : _.has(group, 'totalTime') ? (
                  <span>
                    <strong>Time</strong>{' '}
                    {`${formatTimeHoursMinutesSeconds(group.totalTime)} ${
                      group.totalDuration !== null
                        ? `/ ${formatTimeHoursMinutesSeconds(
                            group.totalDuration
                          )}`
                        : ''
                    }`}
                  </span>
                ) : null}
                <Tooltip title={group.id}>
                  <InfoCircleOutlined
                    onClick={e => {
                      navigator.clipboard.writeText(group.id);
                      message.info('Copied ' + groupBy + ' id to clipboard');
                      e.stopPropagation();
                    }}
                  />
                </Tooltip>
              </Space>
            }
          >
            <Table
              dataSource={group.cmeAttempts}
              rowKey="id"
              columns={[
                {
                  title: 'Type',
                  dataIndex: 'type',
                  key: 'type',
                  render: _.capitalize
                },
                {
                  title: 'Content',
                  key: 'content-type',
                  render: (value, record) =>
                    _.capitalize(getCmeAttemptContentType(record))
                },
                {
                  title: 'Title',
                  key: 'title',
                  render: (value, record) => {
                    const contentType = getCmeAttemptContentType(record);
                    return (
                      <Tooltip title={record[contentType]?.id}>
                        <div
                          onClick={() => {
                            navigator.clipboard.writeText(
                              record[contentType]?.id
                            );
                            message.info('Copied Content id to Clipboard');
                          }}
                        >
                          {contentType === 'assessment'
                            ? getAssessmentForCmeAttempt(record)?.title
                            : contentType === 'qbank'
                            ? getQbankForCmeAttempt(record)?.assessmentTitle
                            : record[contentType]?.title}
                        </div>
                      </Tooltip>
                    );
                  }
                },

                groupBy === 'bucket'
                  ? {
                      title: 'Content Date',
                      key: 'contentDate',
                      render: (value, record) => {
                        const episode = getEpisodeForCmeAttempt(
                          record,
                          courseOrProductId
                        );
                        return episode
                          ? new Date(episode.activatedAt).toLocaleDateString(
                              'en-US',
                              {
                                month: 'short',
                                year: 'numeric'
                              }
                            )
                          : '';
                      }
                    }
                  : {
                      title: 'Accreditor',
                      key: 'accreditor',
                      render: (value, record) => {
                        return record.bucket?.cmeAccreditor?.name || '---';
                      }
                    },
                {
                  title: 'Earned Date',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value, record) => {
                    const contentType = getCmeAttemptContentType(record);
                    return value
                      ? new Date(
                          contentType === 'qbank' ? record.attemptedAt : value
                        ).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })
                      : '';
                  }
                },
                {
                  title: 'Activity Time (hh:mm:ss)/Credits',
                  key: 'credits',
                  render: (value, record) =>
                    record.type === 'attested' ? (
                      `${record.credits} cr`
                    ) : (
                      <Tooltip
                        title={
                          record.accountAssessmentSummariesList?.length ? (
                            <>
                              <div> score </div>
                              {
                                record.accountAssessmentSummariesList[0]
                                  .correctAnswers
                              }
                              /
                              {
                                record.accountAssessmentSummariesList[0]
                                  .totalAnswers
                              }
                            </>
                          ) : (
                            <>
                              <div> duration </div>
                              {formatTimeHoursMinutesSeconds(
                                parseFloat(
                                  record.chapter?.media?.duration ||
                                    record.lecture?.media?.duration ||
                                    record.attachment?.activityTimeInSeconds ||
                                    0
                                )
                              )}
                            </>
                          )
                        }
                      >
                        {formatTimeHoursMinutesSeconds(
                          record.activityTimeInSeconds
                        )}
                      </Tooltip>
                    )
                },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                  render: (value, record) => (
                    <Tooltip title={record.id}>
                      <Tag
                        onClick={() => {
                          navigator.clipboard.writeText(record.id);
                          message.info('Copied CmeAttempt id to Clipboard');
                        }}
                        icon={
                          record.passed ? (
                            <CheckCircleOutlined />
                          ) : (
                            <CloseCircleOutlined />
                          )
                        }
                        color={record.passed ? 'success' : 'error'}
                      >
                        {value.replaceAll('_', ' ')}
                      </Tag>
                    </Tooltip>
                  )
                }
              ]}
            />
          </Panel>
        ))}
      </Collapse>
    </Space>
  ) : (
    <LoadingOutlined />
  );
}

import { useCallback, useEffect, useState } from 'react';
import { validatePearlCsv, ValidationStates, parsePearlsCsv } from './csv';
import { createPearl } from './helpers';

/**
 *
 * @param {string} lectureId
 * @returns {{
 *  file: import('antd/lib/upload/interface').UploadFile,
 *  pearls: @type {{ text: string; timestamp: number | undefined; }[]} | undefined,
 *  validationState: import('./validation').ValidationStates | undefined,
 *  isValid: boolean,
 *  isCreating: boolean,
 *  setFile: (file: import('antd/lib/upload/interface').UploadFile) => void,
 *  create: () => Promise<void>
 * }}
 */
export const usePearlsCsv = lectureId => {
  // state

  const [file, setFile] = useState();
  const [pearls, setPearls] = useState();
  const [validationState, setValidationState] = useState();
  const isValid = validationState && validationState === ValidationStates.VALID;
  const [isCreating, setIsCreating] = useState(false);

  // helpers

  const create = useCallback(async () => {
    if (isValid) {
      setIsCreating(true);
      try {
        const responses = await Promise.allSettled(
          pearls.map((pearl, idx) => createPearl(lectureId, pearl, idx))
        );

        const failedResponses = responses.filter(
          response => response.status === 'rejected'
        );

        if (failedResponses.length > 0) {
          throw new Error(
            `${failedResponses.length} pearls failed to be created.`
          );
        }
      } catch (err) {
        throw err;
      } finally {
        setIsCreating(false);
      }
    }
  }, [isValid, lectureId, pearls]);

  // effects

  useEffect(
    function updateFileMeta() {
      const doUpdate = async () => {
        const csvText = await file.originFileObj?.text();

        const validState = await validatePearlCsv(csvText);
        setValidationState(validState);

        if (validState === ValidationStates.VALID) {
          const pearlCsvResult = await parsePearlsCsv(file);

          if (pearlCsvResult.errors.length > 0) {
            setValidationState(ValidationStates.PARSE_ERROR);
            return;
          }

          setPearls(pearlCsvResult.data);
        }
      };

      if (file) {
        doUpdate();
      }
    },
    [file, isValid]
  );

  // return

  return {
    file,
    pearls,
    validationState,
    isValid,
    isCreating,
    setFile,
    create
  };
};

import { getTokenFromCookie } from './cookie';

// Deletes a file rom AWS

/**
 * @param {string} courseShortname
 * @param {string} imageUrl
 *
 * @return {Promise<string>} deleted file key.
 */
export default async function deleteFromS3(courseShortname, imageUrl) {
  const S3Bucket = process.env.REACT_APP_S3_BUCKET;
  const folder = `images/logos/${courseShortname}`;
  const parts = imageUrl.split('/');
  const filename = parts[parts.length - 1];
  const url = `${process.env.REACT_APP_JSONAPI_SERVER}/api/s3/delete-image`;

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromCookie()}`
    },
    body: JSON.stringify({
      folder,
      filename,
      bucket: S3Bucket
    })
  });

  return response;
}

import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { notify } from 'react-notify-toast';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  DeleteOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  PaperClipOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Button, Spin, Modal } from 'antd';
import isAfter from 'date-fns/isAfter';
import * as API from '../../API';
import getSlug from '../../utils/getSlug';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import AttachmentFileList from '../../components/Attachments/AttachmentFileList';

import './TopicView.css';

import LectureList from './LectureList';
import TopicTags from './TopicTags';
import { TopicLecturesLastReviewedAtModal } from './TopicLecturesLastReviewedAtModal';

const getListStyle = () => ({});

const getRowStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
  justifyContent: 'space-between',
  userSelect: 'none',
  padding: 12,
  background: isDragging ? '#eee' : '#fff',
  boxShadow: isDragging ? 'box-shadow: 0 10px 6px -6px #777' : 'none',
  ...draggableStyle
});

export default class TopicView extends React.Component {
  static propTypes = {
    topicGroupId: PropTypes.string,
    courseId: PropTypes.string,
    attestationCme: PropTypes.bool,
    showExpiredTopics: PropTypes.bool,
    setLectureListRef: PropTypes.func,
    onQueryStart: PropTypes.func,
    onQueryEnd: PropTypes.func
  };

  static defaultProps = {
    topicGroupId: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      topics: [],
      lecturesByTopic: {},
      topicOpen: {},
      pendingQueries: 0,
      attachmentsModalVisible: false,
      selectedTopicId: null,
      attachments: [],
      topicLecturesLastReviewedAtModalTopic: null
    };

    this.lectureListRefs = {};
  }

  toggleAttachmentsModal = topicId => {
    this.setState(prevState => ({
      attachmentsModalVisible: !prevState.attachmentsModalVisible,
      selectedTopicId: topicId || null
    }));
    this.loadAttachments(topicId);
  };

  componentDidMount() {
    this.fetchTopics();
  }

  onQueryStart = count => {
    const pendingQueries = this.state.pendingQueries + count;
    this.setState({ pendingQueries });
  };

  onQueryEnd = () => {
    const pendingQueries = this.state.pendingQueries - 1;
    this.setState({ pendingQueries });
  };

  moveItem = (sourceIndex, targetIndex) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    const { topics } = this.state;
    const topic = topics[sourceIndex];
    topics.splice(sourceIndex, 1);
    topics.splice(targetIndex, 0, topic);
    topics.map((child, i) =>
      this.updateTopic(child.id, 'displayOrder', i * 10)
    );

    this.setState({ topics });
  };

  insertItem = (targetIndex, topic) => {
    const { topics } = this.state;
    topics.splice(targetIndex, 0, topic);
    topics.map((child, i) =>
      this.updateTopic(child.id, 'displayOrder', i * 10)
    );
    this.setState({ topics });
  };

  removeItem = sourceIndex => {
    const { topics } = this.state;
    topics.splice(sourceIndex, 1);
    topics.map((child, i) =>
      this.updateTopic(child.id, 'displayOrder', i * 10)
    );
    this.setState({ topics });
  };

  getTopic = id => _.findWhere(this.state.topics, { id });

  createTopic = async () => {
    const title = 'New Topic';
    try {
      this.onQueryStart(1);
      await API.topic.create({
        attributes: {
          title,
          urlSlug: getSlug(null, title, this.state.topics),
          displayOrder: this.state.topics.length
            ? this.state.topics[this.state.topics.length - 1].attributes
                .displayOrder + 10
            : 0,
          percentageOfCourse: 0.0,
          percentageOfCourse2: 0.0
        },
        relationships: {
          topicGroup: {
            data: {
              type: 'topicGroup',
              id: this.props.topicGroupId
            }
          }
        }
      });

      this.onQueryEnd();
      this.fetchTopics();
    } catch (error) {
      this.setState({ error });
    }
  };

  deleteTopic = async topic => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete topic "${topic.attributes.title}"?`
        )
      ) {
        return;
      }
      this.onQueryStart(1);
      await API.topic.delete({ id: topic.id });
      this.onQueryEnd();
      this.fetchTopics();
    } catch (error) {
      this.setState({ error });
    }
  };

  updateTopic = async (id, field, value) => {
    try {
      const { topics } = this.state;
      const topicIndex = _.findIndex(topics, { id });
      const topic = _.clone(topics[topicIndex]);
      topic.attributes[field] = value;

      if (field === 'title' || field === 'urlSlug') {
        topic.attributes.urlSlug = getSlug(id, value, this.state.topics);
      }

      this.onQueryStart(1);
      await API.topic.update({
        id: topic.id,
        attributes: topic.attributes,
        relationships: {
          topicGroup: {
            data: {
              type: 'topicGroup',
              id: this.props.topicGroupId
            }
          }
        }
      });
      this.onQueryEnd();
      this.setState({ topics });
    } catch (error) {
      if (error.data) {
        this.onQueryEnd();
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  loadAttachments = async (topicId = null) => {
    try {
      const attachments = [];
      if (!topicId) {
        topicId = this.state.selectedTopicId;
      }
      const topic = this.getTopic(topicId);

      if (topic) {
        for (const topicAttachment of topic.relationships.attachments.data) {
          const attachment = (
            await API.attachment.find({
              id: topicAttachment.id,
              options: {}
            })
          ).data;
          attachments.push({
            id: attachment.id,
            ...attachment.attributes
          });
        }
      }
      this.setState({ attachments });
    } catch (error) {
      this.setState({ error });
    }
  };

  fetchTopics = async () => {
    try {
      if (!this.props.topicGroupId) {
        return;
      }

      this.onQueryStart(1);
      const topics = await API.topic.where({
        filter: {
          topicGroup: this.props.topicGroupId
        },
        options: {
          include: 'lectures'
        }
      });
      this.setState({
        topics: topics.data,
        lecturesByTopic: topics.included
          .filter(resource => resource.type === 'lecture')
          .reduce(
            (aggr, lecture) => ({
              ...aggr,
              [lecture.relationships.topic.data.id]: (
                aggr[lecture.relationships.topic.data.id] || []
              ).concat(lecture)
            }),
            {}
          )
      });

      this.loadAttachments();
      this.onQueryEnd();
    } catch (error) {
      this.setState({ error });
      return [];
    }
  };

  isTopicOpen = id => {
    if (this.state.topicOpen[id] === undefined) {
      return false;
    }
    return this.state.topicOpen[id];
  };

  toggleTopicOpen = id => {
    const { topicOpen } = this.state;
    if (topicOpen[id] === undefined) {
      topicOpen[id] = true;
    } else {
      topicOpen[id] = !topicOpen[id];
    }
    this.setState({ topicOpen });
  };

  handleUpdateAttachment = async attachmentId => {
    try {
      this.onQueryStart(1);
      // we don't need to actually Update the attachment binding
      // this should happen from the component, we just need to refresh our view
      await this.fetchTopics();
      this.loadAttachments();
      this.onQueryEnd();
    } catch (error) {
      this.setState({ error });
    }
  };

  handleDeleteAttachment = async attachmentId => {
    try {
      this.onQueryStart(1);
      // we don't need to actually delete the attachment binding
      // this should happen from the component, we just need to refresh our view
      await this.fetchTopics();
      this.loadAttachments();
      this.onQueryEnd();
    } catch (error) {
      this.setState({ error });
    }
  };

  handleCreateAttachment = async attachmentId => {
    const { selectedTopicId } = this.state;
    try {
      this.onQueryStart(1);
      await API.topicAttachmentBinding.create({
        attributes: {
          createdAt: new Date(),
          updatedAt: new Date()
        },
        relationships: {
          topic: {
            data: {
              type: 'topic',
              id: selectedTopicId
            }
          },
          attachment: {
            data: {
              type: 'attachment',
              id: attachmentId
            }
          }
        }
      });

      await this.fetchTopics();
      this.loadAttachments();
      this.onQueryEnd();
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    if (this.state.error !== null) {
      throw this.state.error;
    }

    return (
      <div className="topic-view">
        <div className="topic-header">
          <div className="column-span-small">
            {this.state.pendingQueries > 0 && <Spin />}
          </div>
          <div className="column-span-large">Topic Title</div>
          <div className="column-span-extra-large">Topic Image</div>
          <div className="column-span-medium">URL Slug</div>
          {this.props.attestationCme ? (
            <div className="column-span-medium">Credits</div>
          ) : null}
          <div className="column-span-medium">% of Course</div>
          <div className="column-span-medium">% of Course 2</div>
          <div className="column-span-medium">Last Reviewed</div>
          <div className="column-span-medium" />
        </div>
        {this.state.topics.length === 0 && (
          <div style={getRowStyle(false)}>No data</div>
        )}
        <div>
          <Droppable droppableId={this.props.topicGroupId} type="topic">
            {(providedDrop, snapshotDrop) => (
              <div
                {...providedDrop.droppableProps}
                ref={providedDrop.innerRef}
                style={getListStyle(snapshotDrop.isDraggingOver)}
              >
                {this.state.topics
                  .filter(topic => {
                    if (this.props.showExpiredTopics) {
                      return true;
                    }

                    return (
                      !topic.attributes.expiredAt ||
                      isAfter(+topic.attributes.expiredAt, Date.now())
                    );
                  })
                  .map((child, index) => (
                    <div key={child.id}>
                      {/* Topic info */}
                      <Draggable
                        draggableId={child.id}
                        index={index}
                        type="topic"
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getRowStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="column-span-small">
                              <Button
                                type="primary"
                                icon={
                                  this.isTopicOpen(child.id) ? (
                                    <CaretDownOutlined />
                                  ) : (
                                    <CaretUpOutlined />
                                  )
                                }
                                style={{ lineHeight: '100%' }}
                                onClick={() => this.toggleTopicOpen(child.id)}
                              />
                            </div>
                            <div className="column-span-large">
                              <EditableLabel
                                defaultValue={child.attributes.title}
                                onChange={value =>
                                  this.updateTopic(child.id, 'title', value)
                                }
                              />
                            </div>
                            <div className="column-span-extra-large">
                              <TopicTags
                                topicId={child.id}
                                courseId={this.props.courseId}
                              />
                            </div>
                            <div className="column-span-medium">
                              <EditableLabel
                                defaultValue={child.attributes.urlSlug}
                                validation={/^[\w\d-]+$/}
                                errorMessage="Must be letters, numbers, or -"
                                onChange={value =>
                                  this.updateTopic(child.id, 'urlSlug', value)
                                }
                              />
                            </div>
                            {this.props.attestationCme ? (
                              <div className="column-span-medium">
                                <EditableLabel
                                  defaultValue={child.attributes.credits}
                                  validation={/^[0-9]+(\.[0-9]+)?$/}
                                  errorMessage="Must be a number"
                                  onChange={value =>
                                    this.updateTopic(child.id, 'credits', value)
                                  }
                                />
                              </div>
                            ) : null}
                            <div className="column-span-medium">
                              <EditableLabel
                                defaultValue={Math.round(
                                  child.attributes.percentageOfCourse * 100
                                )}
                                validation={/^([1-9]?[0-9]||100)$/}
                                errorMessage="Must be a number 0-100"
                                formatLabel={value => `${value}%`}
                                onChange={value =>
                                  this.updateTopic(
                                    child.id,
                                    'percentageOfCourse',
                                    value / 100
                                  )
                                }
                              />
                            </div>
                            <div className="column-span-medium">
                              <EditableLabel
                                defaultValue={Math.round(
                                  child.attributes.percentageOfCourse2 * 100
                                )}
                                validation={/^([1-9]?[0-9]||100)$/}
                                errorMessage="Must be a number 0-100"
                                formatLabel={value => `${value}%`}
                                onChange={value =>
                                  this.updateTopic(
                                    child.id,
                                    'percentageOfCourse2',
                                    value / 100
                                  )
                                }
                              />
                            </div>
                            <div className="column-span-medium">
                              <Button
                                type="primary"
                                ghost={true}
                                icon={<EditOutlined />}
                                onClick={() =>
                                  this.setState({
                                    topicLecturesLastReviewedAtModalTopic: child
                                  })
                                }
                              >
                                Edit All
                              </Button>
                            </div>
                            <div className="column-span-medium button-column">
                              <Button
                                type="link"
                                icon={
                                  <PaperClipOutlined
                                    style={{
                                      fontSize: '1.5em',
                                      lineHeight: '100%',
                                      color:
                                        child.relationships.attachments.data
                                          .length > 0
                                          ? 'black'
                                          : 'lightgray'
                                    }}
                                  />
                                }
                                onClick={() => {
                                  this.toggleAttachmentsModal(child.id);
                                }}
                              />
                              <Button
                                type="danger"
                                icon={<DeleteOutlined />}
                                style={{ lineHeight: '100%' }}
                                onClick={() => this.deleteTopic(child)}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                      {/* End topic info */}
                      {/* Lecture info -- only if expanded */}
                      {this.isTopicOpen(child.id) && (
                        <LectureList
                          parent={child}
                          courseId={this.props.courseId}
                          ref={ref =>
                            this.props.setLectureListRef(child.id, ref)
                          }
                          setLectureListRef={this.props.setLectureListRef}
                          onQueryStart={this.onQueryStart}
                          onQueryEnd={this.onQueryEnd}
                        />
                      )}
                    </div>
                  ))}
                {providedDrop.placeholder}

                <div className="topic-footer">
                  <div className="column-span-small">
                    <Button
                      type="primary"
                      style={{ lineHeight: '100%' }}
                      onClick={this.createTopic}
                    >
                      New Topic
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </div>
        {this.state.attachmentsModalVisible && (
          <Modal
            title={`Manage Topic Attachments - ${
              this.getTopic(this.state.selectedTopicId).attributes.title
            }`}
            visible={this.state.attachmentsModalVisible}
            onOk={() => this.toggleAttachmentsModal()}
            onCancel={() => this.toggleAttachmentsModal()}
            footer={null}
            width="85vw"
          >
            <AttachmentFileList
              attachments={this.state.attachments}
              onCreateItem={this.handleCreateAttachment}
              onUpdateItem={this.handleUpdateAttachment}
              onDeleteItem={this.handleDeleteAttachment}
              showCmeFields={false} // Hide the checkbox as not needed here
            />
          </Modal>
        )}
        {this.state.topicLecturesLastReviewedAtModalTopic && (
          <TopicLecturesLastReviewedAtModal
            topic={this.state.topicLecturesLastReviewedAtModalTopic}
            lectures={
              this.state.lecturesByTopic[
                this.state.topicLecturesLastReviewedAtModalTopic.id
              ]
            }
            onClose={success => {
              if (success) {
                // x2 to close and open the topic lecture list
                // which refreshes its internal state
                this.toggleTopicOpen(
                  this.state.topicLecturesLastReviewedAtModalTopic.id
                );
                this.toggleTopicOpen(
                  this.state.topicLecturesLastReviewedAtModalTopic.id
                );
              }
              this.setState({
                topicLecturesLastReviewedAtModalTopic: null
              });
            }}
          />
        )}
      </div>
    );
  }
}

import { gql } from '@apollo/client';

const UPDATE_COURSE_MUTATION = gql`
  mutation UpdateCourse(
    $id: UUID!
    $defaultItemId: UUID
    $updatedAt: Datetime
  ) {
    updateCourse(
      input: {
        patch: { defaultItemId: $defaultItemId, updatedAt: $updatedAt }
        id: $id
      }
    ) {
      course {
        id
        defaultItemId
      }
    }
  }
`;

export { UPDATE_COURSE_MUTATION };

import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Descriptions,
  Select,
  Tooltip,
  Button,
  message,
  Card
} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { notify } from 'react-notify-toast';
import * as API from '../API';

import TopicGroupView from './course/TopicGroupView';
import EpisodeView from './course/EpisodeView';

import MediaInput from '../components/MediaInput';
import CourseSection from '../components/CourseSection';
import CourseDescriptionSection from '../components/CourseDescriptionSection';
import AttachMediaModal from '../components/Attachments/AttachMediaModal';
import CourseCmeDetail from '../components/CourseCmeDetail';
import AttachmentsCourseGuideInput from '../components/Attachments/AttachmentsCourseGuideInput';
import CourseSettings from '../components/CourseSettings';
import ResourceLibraryManager from './resourceLibrary/ResourceLibraryManager';

import { fetchMediaById, savePosterFrame } from '../utils/media';
import { getDisclosures, getCmeBuckets } from '../utils/cme';
import MessageLoader from '../components/MessageLoader';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { COURSE_QUERY } from './course/queries/CourseQueries';
import {
  UPDATE_ACTIVATEDAT_COURSEATTACHMENTBINDING_MUTATION,
  UPDATE_EXPIREDAT_COURSEATTACHMENTBINDING_MUTATION
} from './course/queries/CourseAttachmentBindingMutations';

import './Course.css';

export default function Course() {
  const [courses, setCourses] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [assessmentId, setAssessmentId] = useState(null);
  const [course, setCourse] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [disclosure, setDisclosure] = useState(null);
  const [cmeBuckets, setCmeBuckets] = useState([]);

  // used for showing data when new poster is captured
  const [tempPosterFrame, setTempPosterFrame] = useState('');

  // currently attached media (includes encodings)
  const [attachedMedia, setAttachedMedia] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const [savingMedia, setSavingMedia] = useState(false);
  const [saveMediaForMediaInput, setSaveMediaForMediaInput] = useState(false);
  const [saveMediaForAttachMedia, setSaveMediaForAttachMedia] = useState(false);
  const [item, setItem] = useState(null);
  const [showingMediaModal, setShowingMediaModal] = useState(false);
  const [savingPoster, setSavingPoster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prevCourseId, setPrevCourseId] = useState(null);

  const { id: courseId } = useParams();

  const { data: courseData, loading: loadingCourseData, refetch } = useQuery(
    COURSE_QUERY,
    {
      variables: {
        courseId
      },
      nextFetchPolicy: 'network-only'
    }
  );

  const [updateActivatedAtCourseAttachmentBinding] = useMutation(
    UPDATE_ACTIVATEDAT_COURSEATTACHMENTBINDING_MUTATION
  );

  const [updateExpiredAtCourseAttachmentBinding] = useMutation(
    UPDATE_EXPIREDAT_COURSEATTACHMENTBINDING_MUTATION
  );

  const onAttach = useCallback(() => {
    Promise.all([
      API.course.update({
        id: courseId,
        attributes: {
          media: {
            type: 'media',
            id: item.id
          }
        }
      }),
      fetchMediaById(item.id)
    ])
      .then(([, loadedMedia]) => {
        const newAttachedMedia = loadedMedia.data.media[0];
        setAttachedMedia(newAttachedMedia);
        setSavingMedia(false);
        setSaveMediaForAttachMedia(false);
        setShowingMediaModal(false);
      })
      .catch(e => {
        window.alert(e.message);
        setSavingMedia(false);
        setSaveMediaForAttachMedia(false);
      });
  }, [courseId, item]);

  const onDetach = useCallback(() => {
    API.course
      .update({
        id: course.id,
        attributes: { media: null }
      })
      .then(() => {
        setAttachedMedia(null);
        setSavingMedia(false);
      })
      .catch(e => {
        window.alert(e.message);
        setSavingMedia(false);
      });
  }, [course]);

  const loadData = useCallback(
    async courseData => {
      try {
        setLoading(true);

        const disclosures = await getDisclosures(courseData.course, true);
        const cmeBuckets = await getCmeBuckets(courseData.course, true);

        const assessments = courseData.course.assessmentsList;
        const assessmentId = assessments.find(
          assessment => assessment.isCourseAssessment === true
        )?.id;

        const coursesResponse =
          courses ||
          (
            await API.course.where({
              filter: {
                courseType: courseData.course.courseType.id
              },
              options: {
                sort: 'title'
              }
            })
          ).data;

        setCourses(coursesResponse);
        setCourse(courseData.course);
        setCourseType(courseData.course.courseType);
        setAssessments(assessments);
        setAssessmentId(assessmentId);
        setAttachedMedia(courseData.course.media);
        setDisclosure(disclosures.length ? disclosures[0] : null);
        setCmeBuckets(cmeBuckets.length ? cmeBuckets : []);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.data) {
          notify.show(error.data.errors[0].title, 'error');
        } else {
          throw error;
        }
      }
    },
    [courses]
  );

  useEffect(() => {
    if (saveMediaForAttachMedia) {
      onAttach();
    }
    if (saveMediaForMediaInput) {
      onDetach();
    }
  }, [onAttach, onDetach, saveMediaForAttachMedia, saveMediaForMediaInput]);

  useEffect(() => {
    if (!loadingCourseData && courseId !== prevCourseId) {
      setPrevCourseId(courseId);
      loadData(courseData);
    }
    if (!loadingCourseData) {
      setAttachments(courseData.course?.courseAttachmentBindingsList);
    }
  }, [courseData, courseId, loadData, loadingCourseData, prevCourseId]);

  const getCmeDetails = () => {
    const rows = [
      {
        key: 'disclosure',
        title: 'CME Disclosure',
        activatedAt: disclosure ? disclosure.attributes.activatedAt : '(None)',
        expiredAt: disclosure ? disclosure.attributes.expiredAt : '(None)'
      }
    ];

    cmeBuckets.map(bucket => {
      const creditDescription = bucket.relationships.cmeAccreditor
        ? bucket.relationships.cmeAccreditor.attributes.name
        : 'Unknown';

      return rows.push({
        key: bucket.id,
        title: `${bucket.attributes.title} Credit (${creditDescription})`,
        activatedAt: bucket.attributes.activatedAt,
        expiredAt: bucket.attributes.expiredAt
      });
    });

    return rows;
  };

  const updateAttachmentActivatedAtOnClick = async (
    courseAttachmentBindingId,
    activatedAt
  ) => {
    try {
      const activatedAtUTC = moment
        .utc(moment(activatedAt).startOf('day'))
        .startOf('day');
      await updateActivatedAtCourseAttachmentBinding({
        variables: {
          courseAttachmentBindingId,
          activatedAt: activatedAtUTC,
          updatedAt: new Date()
        }
      });
    } catch (e) {
      message.error('Error while updating attachment activatedAt', 2);
    }
  };

  const updateAttachmentExpiredAtOnClick = async (
    courseAttachmentBindingId,
    expiredAt
  ) => {
    try {
      const expiredAtUTC = moment
        .utc(moment(expiredAt).startOf('day'))
        .startOf('day');
      await updateExpiredAtCourseAttachmentBinding({
        variables: {
          courseAttachmentBindingId,
          expiredAt: expiredAtUTC,
          updatedAt: new Date()
        }
      });
    } catch (e) {
      message.error('Error while updating attachment expiredAt', 2);
    }
  };

  const savePosterFrameMedia = () => {
    setSavingPoster(true);
    (async () => {
      savePosterFrame(
        '#lecture-media video',
        attachedMedia,
        posterFrameMedia => {
          // Update currently attached media.
          const updatedAttachedMedia = attachedMedia
            ? {
                ...attachedMedia,
                posterFrame: posterFrameMedia.posterFrame
              }
            : null;

          setAttachedMedia(updatedAttachedMedia);
          setTempPosterFrame('');
          setSavingPoster(false);
        },
        e => {
          window.alert(e.message);
          setSavingPoster(false);
        },
        tempPosterFrame => {
          setTempPosterFrame(tempPosterFrame);
        }
      );
    })();
  };

  const handleAssessmentChange = async value => {
    await API.assessment.update({
      id: value,
      attributes: {
        isCourseAssessment: true
      }
    });
    for (const assessment of assessments) {
      if (assessment.id !== value) {
        await API.assessment.update({
          id: assessment.id,
          attributes: {
            isCourseAssessment: false
          }
        });
      }
    }
    setAssessmentId(value);
  };

  const courseTypeShortname = courseType && courseType.shortname;

  return (
    <div>
      <Breadcrumb
        tag="nav"
        style={{
          padding: '20px 0'
        }}
      >
        <Breadcrumb.Item>
          <Link to="/dashboard">Hippo Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/courses">Courses</Link>
        </Breadcrumb.Item>
        {courseType && (
          <Breadcrumb.Item>
            <Link to={`/courses/${courseTypeShortname}`}>
              {courseType.title}
            </Link>
          </Breadcrumb.Item>
        )}
        {course ? (
          <Breadcrumb.Item active="true">{course.title}</Breadcrumb.Item>
        ) : null}
      </Breadcrumb>
      <hr />

      {loading ? (
        <MessageLoader />
      ) : (
        <div className="course">
          {course ? (
            <>
              <h3>{course.title}</h3>

              <CourseSettings
                courseTypeShortname={courseTypeShortname}
                course={course}
                loading={loading}
                setCourse={setCourse}
                loadingCourseData={loadingCourseData}
                refetch={refetch}
              />
            </>
          ) : null}

          {course && (
            <CourseDescriptionSection title="Comments and Ratings">
              {course &&
                (courseTypeShortname === 'podcast' ||
                  courseTypeShortname === 'video') && (
                  <Descriptions.Item
                    label={
                      <div className="course__tag-system-label">
                        <div>
                          {courseTypeShortname === 'podcast'
                            ? 'Chapter Comments'
                            : 'Lecture Comments'}
                        </div>
                      </div>
                    }
                  >
                    <Button>
                      <Link to={`/comments/${course.id}`}>Manage Comments</Link>
                    </Button>
                  </Descriptions.Item>
                )}
              <Descriptions.Item
                label={
                  <div className="course__tag-system-label">
                    <div>Ratings</div>
                  </div>
                }
              >
                <Button>
                  <Link to={`/course/${course.id}/ratings`}>View Ratings</Link>
                </Button>
              </Descriptions.Item>
            </CourseDescriptionSection>
          )}

          {course && (
            <CourseCmeDetail course={course} cmeDetails={getCmeDetails()} />
          )}

          {course &&
          (courseTypeShortname === 'other' || // similar to above, 'other' is what board review courses are at the time of writing this
            courseTypeShortname === 'video') ? ( // eventually this will be changed to 'video' so preparing it now. we can remove 'other' from this conditional formatting at that time
            <ResourceLibraryManager courseId={course.id} />
          ) : (
            // 'other' is what board review courses are -- eventually this will be changed to 'video'
            // we can remove 'other' from this conditional formatting at that time
            <CourseDescriptionSection title="Ebook Uploader (Peds RAP and PANRE-LA only)">
              <Card loading={attachments === null}>
                {attachments && (
                  <AttachmentsCourseGuideInput
                    attachments={attachments}
                    onCreateItem={async attachmentId => {
                      await API.courseAttachmentBinding.create({
                        attributes: {
                          createdAt: new Date(),
                          updatedAt: new Date()
                        },
                        relationships: {
                          course: {
                            data: {
                              type: 'course',
                              id: course.id
                            }
                          },
                          attachment: {
                            data: {
                              type: 'attachment',
                              id: attachmentId
                            }
                          }
                        }
                      });
                      await refetch();
                    }}
                    onUpdateItem={async () => {}}
                    updateActivatedAt={updateAttachmentActivatedAtOnClick}
                    updateExpiredAt={updateAttachmentExpiredAtOnClick}
                    onDeleteItem={async () => {}}
                    showCmeFields={true}
                    canEdit={true}
                  />
                )}
              </Card>
            </CourseDescriptionSection>
          )}

          {/* ************** */}
          {/* Course Content */}
          {/* ************** */}

          {course &&
            (courseTypeShortname === 'qbank' ||
              courseTypeShortname === 'practice-exam') && (
              <CourseSection title="Course Questions">
                <Card>
                  <h6>
                    To edit course questions, go to Content &gt; Questions and
                    filter by this course
                  </h6>
                  <Link to="/content/questions">Edit Questions</Link>
                </Card>
              </CourseSection>
            )}

          {course && courseTypeShortname === 'assessment' && (
            <CourseSection title="Course Content">
              <Card>
                <h6>
                  To edit this course's assessment(s), go to Content &gt;
                  Assessment and filter by this course
                </h6>
                <Link to="/content/assessments">Edit Assessments</Link>
              </Card>
              <Card>
                <h6>
                  To edit course questions, go to Content &gt; Questions and
                  filter by this course
                </h6>
                <Link to="/content/questions">Edit Questions</Link>
              </Card>
            </CourseSection>
          )}

          {course && courseTypeShortname === 'bootcamp-assessment' && (
            <CourseSection title="Course Content">
              <Card
                title={
                  <div className="course__tag-system-label">
                    <div>Assessment</div>
                    <Tooltip title="Select which assessment (questions) to show for this course's content.">
                      <InfoCircleFilled className="course__tag-system-label-icon" />
                    </Tooltip>
                  </div>
                }
              >
                <Select
                  defaultValue={
                    assessmentId ? assessmentId : 'Select Assessment'
                  }
                  onChange={value => handleAssessmentChange(value)}
                  className="course__select"
                >
                  {assessments &&
                    assessments.map(assessment => (
                      <Select.Option key={assessment.id}>
                        {assessment.title}
                      </Select.Option>
                    ))}
                </Select>
              </Card>
              <Card>
                <h6>
                  To edit this course's assessment(s), go to Content &gt;
                  Assessment and filter by this course
                </h6>
                <Link to="/content/assessments">Edit Assessments</Link>
              </Card>
              <Card>
                <h6>
                  To edit course questions, go to Content &gt; Questions and
                  filter by this course
                </h6>
                <Link to="/content/questions">Edit Questions</Link>
              </Card>
            </CourseSection>
          )}

          {course && courseTypeShortname === 'podcast' && (
            <CourseSection title="Course Episodes">
              <EpisodeView
                defaultCourseId={course.id}
                course={course}
                courseList={courses}
              />
            </CourseSection>
          )}

          {course && courseTypeShortname === 'video' && (
            <>
              <CourseSection title="Welcome Video">
                <Card>
                  <MediaInput
                    selectedMedia={attachedMedia}
                    posterFrame={
                      tempPosterFrame ||
                      (attachedMedia && attachedMedia.posterFrameUrl)
                    }
                    isSavingPoster={savingPoster}
                    onSelectMedia={() => setShowingMediaModal(true)}
                    onCapturePoster={savePosterFrameMedia}
                    onDetach={() => {
                      setSavingMedia(true);
                      setSaveMediaForMediaInput(true);
                    }}
                  />
                </Card>
                <AttachMediaModal
                  visible={showingMediaModal}
                  courseType="video"
                  isLoading={savingMedia}
                  title="Select Welcome Video"
                  onClose={() => setShowingMediaModal(false)}
                  onAttach={item => {
                    setSavingMedia(true);
                    setItem(item);
                    setSaveMediaForAttachMedia(true);
                  }}
                />
              </CourseSection>
              <TopicGroupView
                courseId={course.id}
                attestationCme={course.attestationCme}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

import { gql } from '@apollo/client';

const GET_ITEMS_CATEGORIES_QUERY = gql`
  query GetItemsCategories {
    __type(name: "EnumItemsCategory") {
      enumValues {
        name
      }
    }
  }
`;

export default GET_ITEMS_CATEGORIES_QUERY;

import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import GET_ITEMS_CATEGORIES_QUERY from './queries/GetItemsCategories';
import _ from 'lodash';

export const useItemsCategories = () => {
  const { data } = useQuery(GET_ITEMS_CATEGORIES_QUERY);
  const itemsCategories = useMemo(
    () =>
      data?.__type?.enumValues?.map(({ name }) => ({
        label: getCategoryLabel(name),
        value: name
      })),
    [data]
  );

  return itemsCategories;
};

const getCategoryLabel = categoryName => {
  const customFormatting = {
    PA_NP: 'PA/NP',
    PA: 'PA',
    PA_STUDENT: 'PA Student'
  }[categoryName];

  return (
    customFormatting ??
    _.startCase(categoryName.toLowerCase().replace(/_/g, ' '))
  );
};
